import React from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    TextField,
    Container,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    CssBaseline,
    Box,
    Icon,
    Switch,
    FormControlLabel,
    Paper,
    List,
    ListItem,
    ListItemText,
    Avatar,
} from "@mui/material";
import { ExpandMore, Close, Search } from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: grey[900],
            paper: grey[800],
        },
        text: {
            primary: "#fff",
        },
        primary: {
            main: "#178d72",
        },
    },
});

const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#178d72",
        },
    },
});

const test = () => {
    const [darkMode, setDarkMode] = React.useState(false);
    const theme = darkMode ? darkTheme : lightTheme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Lexend
                        </Typography>
                        <Button color="inherit">Request a demo</Button>
                        <Button color="inherit">Log in</Button>
                        <Button color="inherit">Start free trial</Button>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={darkMode}
                                    onChange={() => setDarkMode(!darkMode)}
                                    name="darkMode"
                                />
                            }
                            label="Dark Mode"
                        />
                    </Toolbar>
                </AppBar>
                <Container maxWidth="xl" sx={{ py: 6 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={5}>
                            <Typography variant="h4" gutterBottom>
                                Streamline your Tool Workflow
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Keep data consistent, with native CRM integrations that
                                streamline your entire Tool workflow.
                            </Typography>
                            <TextField
                                fullWidth
                                label="Your email address"
                                variant="outlined"
                                sx={{ my: 2 }}
                            />
                            <Button variant="contained" color="primary" fullWidth>
                                Start free trial
                            </Button>
                            <Typography variant="body2" color="textSecondary">
                                No credit card required.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    alt="Main hero image"
                                    height="140"
                                    image="https://via.placeholder.com/600"
                                />
                            </Card>
                        </Grid>
                    </Grid>

                    <Box sx={{ my: 6 }}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Smart features for your business
                        </Typography>
                        <Typography variant="body1" align="center" paragraph>
                            We focus on helping you to make useful content more accessible
                            with an ultimate goal for a good sharing profit as a content
                            creator.
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>
                                            Share tools quickly and confidently in minutes
                                        </Typography>
                                        <Typography variant="body1">
                                            This powerful tool eliminates the need to leave Salesforce
                                            to get things done as I can create a custom proposal with
                                            dynamic pricing tables.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>
                                            Connect every part of your entire business
                                        </Typography>
                                        <Typography variant="body1">
                                            Keep data consistent, with native CRM integrations that
                                            streamline your entire Tool workflow.
                                        </Typography>
                                        <Link href="#" underline="hover">
                                            See all integrations
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>
                                            Maintain compliance and control your apps
                                        </Typography>
                                        <Typography variant="body1">
                                            Improve security and trust with built-in legally binding
                                            e-Signatures.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ my: 6 }}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Frequently asked questions
                        </Typography>
                        <Paper elevation={3} sx={{ p: 4 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h6">
                                        Do I need to know about how to code?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Yes, you need to have a fair amount of knowledge in dealing
                                        with HTML/CSS as well as JavaScript in order to be able to
                                        use Lexend.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="h6">
                                        Can I use it for commercial projects?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Feel free to do so. Lexend does exist to evolve every
                                        commercial project.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography variant="h6">
                                        Can I use it for multiple projects?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Definitely! Please use it however you like; we don’t limit it.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Box>

                    <Box sx={{ my: 6 }}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Some clients feedback
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body1">
                                            “We’re looking for people who share our vision! most of
                                            our time used to be taken up by most of alternate
                                            administrative work whereas now we can focus on building
                                            out to help our employees.”
                                        </Typography>
                                    </CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                                        <Avatar
                                            src="https://via.placeholder.com/40"
                                            alt="Mark Zellers"
                                        />
                                        <Box sx={{ ml: 2 }}>
                                            <Typography variant="subtitle1">Mark Zellers</Typography>
                                            <Typography variant="body2">CEO, Co-Founder</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body1">
                                            “This powerful tool eliminates the need to leave
                                            Salesforce to get things done as I can create a custom
                                            proposal with dynamic pricing tables, and get approval
                                            from my boss all within 36 minutes.”
                                        </Typography>
                                    </CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                                        <Avatar
                                            src="https://via.placeholder.com/40"
                                            alt="Natalia Larsson"
                                        />
                                        <Box sx={{ ml: 2 }}>
                                            <Typography variant="subtitle1">
                                                Natalia Larsson
                                            </Typography>
                                            <Typography variant="body2">Director of Sales</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body1">
                                            “We are based in Europe and the latest Data Protection
                                            Regulation forces us to look for service suppliers than
                                            comply with this regulation.”
                                        </Typography>
                                    </CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                                        <Avatar
                                            src="https://via.placeholder.com/40"
                                            alt="Sarah Edrissi"
                                        />
                                        <Box sx={{ ml: 2 }}>
                                            <Typography variant="subtitle1">Sarah Edrissi</Typography>
                                            <Typography variant="body2">Lead Marketing</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                        <Link href="#" underline="hover" sx={{ mt: 2, display: "block", textAlign: "center" }}>
                            See all feedbacks
                        </Link>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default test;
