import React, {useEffect, useState} from "react";
import {UploadService} from "../../services/dao/uploader-service";
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {ReceiptsService} from "../../services/dao/receipts-service";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    table: {
        minWidth: 650,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    header: {
        background: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    actionIcon: {
        marginRight: theme.spacing(1),
    },
}));

const uploaderService = new UploadService();
const receiptsService = new ReceiptsService();

export function UploadsList(props) {
    const classes = useStyles();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const load = async () => {
            let response = await uploaderService.getUploads();
            if (response && !response.error) {
                setFiles(response.body);
            }
            // Logger.info("Response: " + JSON.stringify(response?.body))
        };
        load();
    }, []);

    const handleDelete = (uuid) => {
        receiptsService.deleteSampleFileAndSecflows(uuid);
        setFiles(files.filter(file => file.uuid !== uuid));
    };

    return (
        <Container className={classes.root}>
            <Typography variant="h4" gutterBottom>
                Uploaded Files
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Total Files: {files.length}
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="uploaded files table">
                    <TableHead className={classes.header}>
                        <TableRow>
                            <TableCell>File Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Processed</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map((row) => (

                            <TableRow hover>
                                {row.meta && <Box>

                                    <TableCell>
                                        <Link to={`/receipts/${row.meta.uuid}`} className={classes.link}>
                                            {row.meta.fileName}
                                        </Link>
                                    </TableCell>
                                    <TableCell>Receipt</TableCell>
                                    <TableCell>{row.meta.createdDate}</TableCell>
                                    <TableCell>{row.meta.processed ? "Yes" : "No"}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDelete(row.meta.uuid)} color="error">
                                            <DeleteIcon/>
                                        </IconButton>
                                        <IconButton color="primary">
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </TableCell>
                                </Box>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}
