import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Button, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Logger } from "@aws-amplify/core";
import { FindocsService } from "../../../services/dao/findocs-service";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MostVisitedShops = () => {
    const logger = new Logger("MostVisitedShops");
    const findocsService = new FindocsService();

    const [itemsToShow, setItemsToShow] = useState(10);
    const [mostVisitedStores, setMostVisitedStores] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const fetchMostVisitedStores = async () => {
            try {
                const filters = {};
                if (startDate) filters.startDate = startDate;
                if (endDate) filters.endDate = endDate;

                let response = await findocsService.getMostVisitedStores(filters);
                if (response && !response.error) {
                    setMostVisitedStores(response.body);
                }
            } catch (error) {
                logger.error("Failed to fetch most visited stores:", error);
            }
        };

        fetchMostVisitedStores();
    }, [startDate, endDate]);

    const handleZoomIn = () => {
        setItemsToShow(prevItems => Math.max(prevItems - 5, 5)); // Minimum 5 items to show
    };

    const handleZoomOut = () => {
        setItemsToShow(prevItems => Math.min(prevItems + 5, mostVisitedStores.length)); // Maximum all items
    };

    const chartData = {
        labels: mostVisitedStores.slice(0, itemsToShow).map(item => item.description || 'No Description'),
        datasets: [
            {
                label: 'Total Amount',
                data: mostVisitedStores.slice(0, itemsToShow).map(item => item.totalAmount),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Count',
                data: mostVisitedStores.slice(0, itemsToShow).map(item => item.count),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Most Visited/Spent',
            },
        },
    };

    return (
        <div>
            <Box display="flex" justifyContent="center" mb={2}>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginRight: '10px' }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginRight: '10px' }}
                />
                <Button variant="contained" color="primary" onClick={handleZoomIn} disabled={itemsToShow <= 5}>
                    Zoom In
                </Button>
                <Button variant="contained" color="primary" onClick={handleZoomOut} disabled={itemsToShow >= mostVisitedStores.length} style={{ marginLeft: '10px' }}>
                    Zoom Out
                </Button>
            </Box>
            <Bar data={chartData} options={options} />
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Total Amount</TableCell>
                            <TableCell align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mostVisitedStores.slice(0, itemsToShow).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.description || 'No Description'}
                                </TableCell>
                                <TableCell align="right">{row.totalAmount}</TableCell>
                                <TableCell align="right">{row.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default MostVisitedShops;
