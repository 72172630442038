import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Logger} from "@aws-amplify/core";
import {ReceiptsService} from "../../services/dao/receipts-service";
import {useHistory, useParams} from "react-router-dom";
import {Auth} from "aws-amplify";
import Box from "@mui/material/Box";
import ReceiptDetailsCard from "./receipt-details-card";
import {Button} from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%"
    },
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));
const logger = new Logger("ReceiptsDetailsScreen");
const receiptsService = new ReceiptsService();

export default function ReceiptsDetailsScreen() {
    const classes = useStyles();
    const [receiptDetails, setReceiptDetails] = useState(null);
    const history = useHistory();

    let {receiptId} = useParams();

    logger.info("ReceiptID:   " + receiptId)
    useEffect(() => {

        const load = async () => {
            let receiptInfo
                = await receiptsService.getReceipt(receiptId)

            let session = await Auth.currentSession();
            logger.info("sub: " + session["accessToken"]["payload"]["sub"])

            setReceiptDetails(receiptInfo.body);
        }

        load();

    }, [receiptId])

    const handleNavigateBack = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Box sx={{ marginBottom: 2 }}>
                <Button variant="text" startIcon={<ArrowBack />} color="primary" onClick={handleNavigateBack}>
                    Navigate Back
                </Button>
            </Box>
            {receiptDetails && <div>
                <Box sx={{flexGrow: 1, overflow: 'hidden'}}>
                    <ReceiptDetailsCard receipt={receiptDetails}/>
                </Box>

            </div>}


        </div>
    );
}
