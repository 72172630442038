import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Logger} from "@aws-amplify/core";
import {Grid, Typography} from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 400,
        // backgroundColor: theme.palette.background.paper,
    },
}));

const logger = new Logger("FaqScreen");


export default function FaqScreen() {
    const classes = useStyles();

    return <div className={classes.root}>
        <div style={{width: '100%'}}>

            <Grid container>
                <Grid item xs={12}>
                    <Typography/>
                </Grid>
            </Grid>

        </div>

    </div>
}
