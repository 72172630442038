import * as React from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    CssBaseline,
    GlobalStyles,
    Grid,
    styled,
    Typography,
    useTheme
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const tiers = [
    {
        title: 'Starter',
        price: '4.99',
        priceId: "price_1PMikkB3lei9xMKL9HkKNXhn",
        paymentLink: "https://buy.stripe.com/00g7wsfnS8am2pG145",
        description: [
            { title: "Receipts", value: '20/month' },
            { title: "Bank Statements", value: '2/month' },
            { title: "API", value: false },
            { title: "Receipts OCR", value: false },
            { title: "Receipts Itemization", value: false },
            { title: "Receipts CSV", value: false },
            { title: "Bank Statements OCR", value: false },
            { title: "Bank Statements Itemization", value: false },
            { title: "Bank Statements CSV", value: false },
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
    },
    {
        title: 'Standard',
        price: '9.99',
        priceId: "price_1PMilTB3lei9xMKLggTNqD8E",
        paymentLink: "https://generic.app.assetsmaven.com/assetsmaven/api/v1/payment/initialize",
        description: [
            { title: "Receipts", value: '100/month' },
            { title: "Bank Statements", value: '10/month' },
            { title: "API", value: false },
            { title: "Receipts OCR", value: true },
            { title: "Receipts Itemization", value: true },
            { title: "Receipts CSV", value: true },
            { title: "Bank Statements OCR", value: true },
            { title: "Bank Statements Itemization", value: true },
            { title: "Bank Statements CSV", value: true },
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
    },
    {
        title: 'Standard w/ API',
        price: '19.99',
        priceId: "price_1KvmE5I2zPxrrf3ciMmZ7qCO",
        description: [
            { title: "Receipts", value: '200/month' },
            { title: "Bank Statements", value: '30/month' },
            { title: "API", value: true },
            { title: "Receipts OCR", value: true },
            { title: "Receipts Itemization", value: true },
            { title: "Receipts CSV", value: true },
            { title: "Bank Statements OCR", value: true },
            { title: "Bank Statements Itemization", value: true },
            { title: "Bank Statements CSV", value: true },
        ],
        buttonText: "Coming soon",
        buttonVariant: 'disabled',
    },
];

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(6, 0),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    borderRadius: '15px',
}));

const renderFeatureValue = (value) => {
    if (typeof value === 'boolean') {
        return value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />;
    }
    return value;
};

const PricingContent = () => {
    const theme = useTheme();

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <StyledContainer maxWidth="lg">
                <Grid container spacing={4} justifyContent="center">
                    {tiers.map((tier, index) => (
                        <Grid item key={tier.title} xs={12} sm={6} md={4}>
                            <StyledCard style={{ backgroundColor: index === 1 ? '#694dbf' : 'white', color: index === 1 ? 'white' : 'black' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {tier.title}
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', my: 2 }}>
                                    ${tier.price}/mo
                                </Typography>
                                <Box component="ul" sx={{ p: 0 }}>
                                    {tier.description.map((feature, i) => (
                                        <Typography component="li" key={i} sx={{ mb: 1 }}>
                                            {feature.title}: {renderFeatureValue(feature.value)}
                                        </Typography>
                                    ))}
                                </Box>
                                <Button href={tier.paymentLink} variant="contained" sx={{ mt: 2, backgroundColor: index === 1 ? 'white' : '#4c6ef5', color: index === 1 ? '#4c6ef5' : 'white' }} disabled={tier.buttonVariant === 'disabled'}>
                                    {tier.buttonText}
                                </Button>
                            </StyledCard>
                        </Grid>
                    ))}
                </Grid>
            </StyledContainer>
        </React.Fragment>
    );
};

export default function PricingPlans() {
    return <PricingContent />;
}
