import * as React from 'react';
import {useState} from 'react';
import {styled} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import Chip from "@mui/material/Chip";
import {LabelsService} from "../../services/dao/labels-service";
import {Logger} from "@aws-amplify/core";

const filter = createFilterOptions();
const ListItem = styled('li')(({theme}) => ({
    margin: theme.spacing(0.5),
}));

const logger = new Logger("LabelsAutocomplete");
const labelsService = new LabelsService();

export default function LabelsAutocomplete() {

    const [myLabels, setMyLabels] = useState([]);

    // useEffect(() => {
    //     logger.info("getting my labels")
    //     const getMyLabels = async () => {
    //
    //         let receiptId = props.data.sk.split("#")[1]
    //
    //         let response = await labelsService.getMyLabels();
    //
    //         if (response && !response.error)
    //             setMyLabels(response.body);
    //         logger.info("Response: " + JSON.stringify(response?.body))
    //     }
    //     getMyLabels();
    //
    // }, [])

    const [labelsArray, setLabelsArray] = React.useState([
        {key: 0, label: 'taxes2022'},
        {key: 1, label: 'home -- living room'},
        {key: 2, label: 'school'},
        {key: 3, label: 'work'},
        {key: 4, label: 'taxes2021'},
    ]);

    const [value, setValue] = React.useState([]);

    return (

        <Autocomplete
            multiple
            multiline
            size="small"
            id="tags-filled"
            style={{width: "100%", flexGrow: 1}}
            fullWidth
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            options={labelsArray.map((option) => option.label)}
            // defaultValue={[top100Films[13].label]}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({index})} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Select or add label"
                    placeholder="label"
                    style={{width: "100%"}}
                    fullWidth

                />
            )}
        />
    );
}
