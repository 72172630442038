import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TableChartIcon from '@mui/icons-material/TableChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MostVisitedShops from "./findoc-most-visited";
import TopSpendingCategories from "./findoc-top-spending-categories";
import MonthlySpending from "./findoc-monthly-spending";
import SubscriptionTable from "./findoc-subscriptions";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TimelineIcon from '@mui/icons-material/Timeline';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
        },
    },
    controlPanel: {
        backgroundColor: "#eceef3",
        width: '25%',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        position: 'fixed',
        height: '100vh',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            height: 'auto',
            overflowY: 'visible',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    findocsPanel: {
        flexGrow: 1,
        marginLeft: '25%',
        padding: theme.spacing(3),
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            padding: theme.spacing(2),
        },
    },
    listItem: {
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
        '&.Mui-selected': {
            backgroundColor: '#e0e0e0',
            '&:hover': {
                backgroundColor: '#d5d5d5',
            },
        },
    },
}));

export function DashboardScreen() {
    const classes = useStyles();
    const [selectedRoute, setSelectedRoute] = useState("/");

    const menuItems = [
        { text: "Subscriptions", icon: <LoyaltyIcon />, route: "/dashboard/subscriptions" },
        { text: "Monthly Spend", icon: <TimelineIcon />, route: "/dashboard/monthly" },
        { text: "Top Spending Categories", icon: <TableChartIcon />, route: "/dashboard/topcategories" },
        // { text: "Profile", icon: <AccountCircleIcon />, route: "/profile" },
        // { text: "Sign In", icon: <LockIcon />, route: "/sign-in" },
        // { text: "RTL Admin", icon: <AdminPanelSettingsIcon />, route: "/rtl-admin" },
    ];

    return (
        <Router>
            <Box className={classes.root}>
                {/* Control Panel */}
                <Box className={classes.controlPanel}>
                    <List>
                        {menuItems.map((item, index) => (
                            <ListItem
                                button
                                key={index}
                                className={classes.listItem}
                                selected={selectedRoute === item.route}
                                onClick={() => setSelectedRoute(item.route)}
                                component={Link}
                                to={item.route}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Graph Display Panel */}
                <Box className={classes.findocsPanel}>
                    <Switch>
                        <Route exact path="/dashboard" component={MonthlySpending} />
                        <Route path="/dashboard/subscriptions" component={SubscriptionTable} />
                        <Route path="/dashboard/monthly" component={MonthlySpending} />
                        <Route path="/dashboard/topcategories" component={TopSpendingCategories} />
                        <Route path="/sign-in" component={() => <div>Sign In Component</div>} />
                        <Route path="/rtl-admin" component={() => <div>RTL Admin Component</div>} />
                    </Switch>
                </Box>
            </Box>
        </Router>
    );
}
