import React, { useState } from 'react';
import {
    Stepper, Step, StepLabel, Button, Typography, Box
} from '@mui/material';

const stepsData = {
    0: {
        label: 'Download Statement',
        description: 'Go to your credit card company\'s website and download your PDF statement.',
        image: '/instructions/step1.webp'
    },
    1: {
        label: 'Upload to AssetsMaven',
        description: 'Upload the downloaded statement to AssetsMaven.',
        image: '/instructions/step2.webp'
    },
    2: {
        label: 'Wait for Result',
        description: 'Wait for the analysis result to be processed and displayed.',
        image: '/instructions/step3.webp'
    }
};

const FindocsInstructions = () => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    // Ensure stepsData is defined and has properties
    const totalSteps = Object.keys(stepsData).length;

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {Object.values(stepsData).map((step, index) => (
                    <Step key={index}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === totalSteps ? (
                    <div>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Step {activeStep + 1}
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {stepsData[activeStep]?.description}
                        </Typography>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + stepsData[activeStep]?.image}
                                alt={`Step ${activeStep + 1}`}
                                style={{ width: '100%', maxHeight: 400, objectFit: 'contain' }}
                            />
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Button
                                onClick={handleNext}
                                disabled={activeStep === totalSteps - 1}
                            >
                                {activeStep === totalSteps - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </div>
                )}
            </div>
        </Box>
    );
};

export default FindocsInstructions;
