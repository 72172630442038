import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    Menu,
    MenuItem,
    Snackbar,
    Alert,
    Chip
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};
const imageModalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
};

const ReceiptCard = ({ receipt }) => {
    const [open, setOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const toggleImageModal = () => setImageOpen(!imageOpen);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleReanalyze = () => {
        // Implement reanalyze functionality
        setAlertMessage("Reanalyze request sent successfully");
        setAlertOpen(true);
        handleMenuClose();
    };

    const handleDelete = () => {
        // Implement delete functionality
        setAlertMessage("Document deleted successfully");
        setAlertOpen(true);
        handleMenuClose();
    };

    const handleDownloadCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";

        // Adding receipt metadata
        csvContent += "ID,Vendor Name,Date Created,Total,Address,City,State,Zip Code,Item,Product Code,Price\n";
        receipt.lineItems.forEach(item => {
            csvContent += `${receipt._id},${receipt.vendorName},${receipt.invoiceReceiptDate},${receipt.total},${receipt.address},${receipt.city},${receipt.state},${receipt.zipCode}${item.item || ''},${item.productCode},${item.price}\n`;
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "receipt_details.csv");
        document.body.appendChild(link);

        link.click();
        link.remove();
    };

    const imageUrl = `https://cdn.assetsmaven.com/${encodeURIComponent(receipt.imageLocation)}`;

    const renderTable = () => {
        if (matches) {
            return receipt.lineItems.map((lineItem, index) => (
                <Box key={index} sx={{ marginBottom: 2, border: '1px solid #e0e0e0', borderRadius: '8px', padding: 2 }}>
                    <Typography variant="body1"><strong>Item:</strong> {lineItem.item || "Unnamed Item"}</Typography>
                    <Typography variant="body1"><strong>Product Code:</strong> {lineItem.productCode}</Typography>
                    <Typography variant="body1"><strong>Price:</strong> ${parseFloat(lineItem.price)}</Typography>
                </Box>
            ));
        } else {
            return (
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="line items table" sx={{ minWidth: 650, width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell align="right">Product Code</TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receipt.lineItems.map((lineItem, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {lineItem.item || "Unnamed Item"}
                                    </TableCell>
                                    <TableCell align="right">{lineItem.productCode}</TableCell>
                                    <TableCell align="right">
                                        ${parseFloat(lineItem.price)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, width: '100%' }}>
            <Card sx={{
                width: '100%',
                margin: 'auto',
                boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
                borderRadius: '16px',
                border: '1px solid #e0e0e0',
                '&:hover': {
                    boxShadow: '1px 2px 10px rgba(0,0,0,0.3)',
                }
            }}>
                {receipt.imageLocation && (
                    <CardMedia
                        component="img"
                        height="194"
                        image={imageUrl}
                        alt="Receipt Image"
                        onClick={toggleImageModal}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                {receipt.vendorName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                {receipt.invoiceReceiptDate}
                            </Typography>
                        </Grid>
                        {/*<Grid item xs={4} sx={{ textAlign: 'right' }}>*/}
                        {/*    <IconButton*/}
                        {/*        aria-label="more"*/}
                        {/*        aria-controls="long-menu"*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        onClick={handleMenuClick}*/}
                        {/*    >*/}
                        {/*        <MoreVertIcon />*/}
                        {/*    </IconButton>*/}
                        {/*    <Menu*/}
                        {/*        id="long-menu"*/}
                        {/*        anchorEl={anchorEl}*/}
                        {/*        open={menuOpen}*/}
                        {/*        onClose={handleMenuClose}*/}
                        {/*        PaperProps={{*/}
                        {/*            style: {*/}
                        {/*                maxHeight: 48 * 4.5,*/}
                        {/*                width: '20ch',*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <MenuItem onClick={handleReanalyze}>*/}
                        {/*            <ReplayIcon sx={{ marginRight: 1 }} />*/}
                        {/*            Reanalyze*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem onClick={handleDelete}>*/}
                        {/*            <DeleteIcon sx={{ marginRight: 1 }} />*/}
                        {/*            Delete*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem onClick={handleDownloadCSV}>*/}
                        {/*            <FileDownloadIcon sx={{ marginRight: 1 }} />*/}
                        {/*            Download CSV*/}
                        {/*        </MenuItem>*/}
                        {/*    </Menu>*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Chip icon={<AttachMoneyIcon />} label={receipt.total} size="small" variant="outlined" />
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
                        Address: {receipt.address}
                    </Typography>
                    <Link to={"/receipts/" + receipt._id} style={{ textDecoration: 'none', width: '100%', display: 'block', marginBottom: '16px' }}>
                        <Button variant="contained" color="primary" fullWidth>
                            View Details
                        </Button>
                    </Link>
                    <Button
                        startIcon={<ReceiptLongIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={handleOpen}
                        fullWidth
                        sx={{ marginBottom: '8px' }}
                    >
                        Items
                    </Button>
                </CardContent>
            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle, overflow: 'hidden', maxHeight: '90vh' }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Line Items ({receipt.lineItems.length})
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Box sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        {renderTable()}
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={imageOpen}
                onClose={toggleImageModal}
                aria-labelledby="image-modal-title"
                aria-describedby="image-modal-description"
            >
                <Box sx={imageModalStyle}>
                    <IconButton
                        onClick={toggleImageModal}
                        color="inherit"
                        sx={{ position: 'fixed', top: 8, right: 8, color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={imageUrl} alt="Receipt" style={{ maxHeight: '90%', maxWidth: '90%' }} />
                </Box>
            </Modal>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ReceiptCard;
