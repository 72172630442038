import React, {useEffect, useState} from "react";
import {Box, Button, Grid, IconButton, InputAdornment, Pagination, TextField, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import ReceiptCard from "./receipt-card";
import {ReceiptsService} from "../../services/dao/receipts-service";
import {Logger} from "@aws-amplify/core";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import {saveAs} from 'file-saver';
import {SearchService} from "../../services/dao/search-service";
import SearchResult from "./components/receipt-search-result";
import FindocsInstructions from "../findoc/findocs-instructions";
import ReceiptInstructions from "./receipt-instructions";
import ReceiptStepper from "./receipt-instructions"; // Assuming SearchResult is in the same folder

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
        },
    },
    controlPanel: {
        backgroundColor: "#eceef3",
        width: '25%',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        position: 'fixed',
        height: '100vh',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            height: 'auto',
            overflowY: 'visible',
        },
    },
    receiptsPanel: {
        flexGrow: 1,
        marginLeft: '25%',
        padding: theme.spacing(3),
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            padding: theme.spacing(2),
        },
    },
    header: {
        margin: 4,
    },
    downloadButton: {
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginBottom: theme.spacing(3),
    },
    pagination: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    searchBox: {
        display: 'flex',
        alignItems: 'center',
    },
    resetButton: {
        marginLeft: theme.spacing(2),
    },
}));

const logger = new Logger("ReceiptListScreen");
const receiptsService = new ReceiptsService();
const searchService = new SearchService();

export function ReceiptListScreen(props) {
    const classes = useStyles();
    const [receiptsList, setReceiptsList] = useState(null);
    const [filteredReceipts, setFilteredReceipts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    useEffect(() => {
        const searchEverything = async () => {
            let response = await searchService.searchReceipts(searchQuery);
            logger.info("Searched...got " + JSON.stringify(response.body))
            if (response && !response.error && response.body && response.body.length > 0) {
                setSearchResults(response.body);
                setFilteredReceipts(response.body);
            } else {
                setSearchResults([]);
                setFilteredReceipts([]);
            }
        };
        if (searchQuery != null && searchQuery !== "" && searchQuery.length > 2)
            searchEverything();
    }, [searchQuery]);

    useEffect(() => {
        const getMyReceipts = async () => {
            let response = await receiptsService.getMyReceipts();
            if (response && !response.error) {
                setReceiptsList(response.body);
                setFilteredReceipts(response.body);
            }
        };
        getMyReceipts();
    }, []);

    const handleDownloadDisplayedReceipts = async () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Receipt ID\tVendor Name\tDate Created\tTotal\tAddress\tItem\tProduct Code\tPrice\n";

        const fetchDetailsPromises = filteredReceipts.map(async (receipt) => {
            return receipt.lineItems.map(lineItem => {
                return `${receipt._id}\t${receipt.vendorName}\t${receipt.dateCreated}\t${receipt.total}\t${receipt.address}\t${lineItem.item}\t${lineItem.productCode}\t${lineItem.price}`;
            }).join("\n");
        });

        const allReceiptDetails = await Promise.all(fetchDetailsPromises);
        csvContent += allReceiptDetails.join("\n");

        const blob = new Blob([csvContent], {type: 'text/tsv;charset=utf-8;'});
        saveAs(blob, "displayed_receipts_details.tsv");
    };

    const handleReset = () => {
        setSearchQuery("");
        setSearchResults([]);
        setFilteredReceipts(receiptsList);
    };

    // Calculate the currently displayed items
    const indexOfLastReceipt = currentPage * itemsPerPage;
    const indexOfFirstReceipt = indexOfLastReceipt - itemsPerPage;
    const currentReceipts = filteredReceipts.slice(indexOfFirstReceipt, indexOfLastReceipt);

    // Change page
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    // Calculate total pages
    const totalPages = Math.ceil(filteredReceipts.length / itemsPerPage);

    return (
        <Box className={classes.root}>
            {/* Control Panel */}
            <Box className={classes.controlPanel}>
                <Typography variant="h5" component="h5" className={classes.header} color="textPrimary">
                    Uploaded Receipts
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadIcon/>}
                    onClick={handleDownloadDisplayedReceipts}
                    className={classes.downloadButton}
                >
                    CSV ({filteredReceipts.length} receipts)
                </Button>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                    Displayed Receipts: {filteredReceipts.length}
                </Typography>
            </Box>

            {/* Receipts Panel */}

            {!receiptsList || receiptsList.length === 0 && <Box className={classes.receiptsPanel}>
                <ReceiptStepper/>
            </Box>}
            {receiptsList && receiptsList.length > 0 && <Box className={classes.receiptsPanel}>
                <TextField
                    className={classes.searchInput}
                    label="Search Receipts"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        endAdornment: searchQuery && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleReset}>
                                    <CloseIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {searchResults.length > 0 ? (
                    searchResults.map((result, index) => (
                        <SearchResult key={index} data={result} searchQuery={searchQuery}/>
                    ))
                ) : (
                    <>
                        {/* Pagination Controls */}
                        {totalPages > 1 && (
                            <Pagination
                                className={classes.pagination}
                                count={totalPages}
                                page={currentPage}
                                onChange={handleChangePage}
                                color="primary"
                            />
                        )}
                        <Grid container spacing={4} justifyContent="center">
                            {currentReceipts.map((receipt, index) => (
                                <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                                    <ReceiptCard receipt={receipt}/>
                                </Grid>
                            ))}
                        </Grid>
                        {/* Pagination Controls */}
                        {totalPages > 1 && (
                            <Pagination
                                className={classes.pagination}
                                count={totalPages}
                                page={currentPage}
                                onChange={handleChangePage}
                                color="primary"
                            />
                        )}
                    </>
                )}
            </Box>}
        </Box>
    );
}
