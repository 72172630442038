const prod = {
    uploaderService: "https://generic.app.assetsmaven.com/assetsmaven/api/v1",
    genericService: "https://generic.app.assetsmaven.com/assetsmaven/api/v1",
    genericPublicService: "https://generic.app.assetsmaven.com/assetsmaven/api/v1",
    logLevel: 'INFO',
    stripePromise: "pk_test_51KqFWAI2zPxrrf3ceTvfnD5q5jdx5IbV7OOwqQFvpPdO8wJ8ihycZSEiEdqCR9EsaQaYOTAM8UV4EIxzOPzoQmFo00VOL4Rngh"

};

const dev = {
    uploaderService: "http://localhost:8080/assetsmaven/api/v1",
    genericService: "http://localhost:8080/assetsmaven/api/v1",
    genericPublicService: "http://localhost:8080/assetsmaven/api/v1",
    logLevel: 'INFO',
    stripePromise: "pk_test_51KqFWAI2zPxrrf3ceTvfnD5q5jdx5IbV7OOwqQFvpPdO8wJ8ihycZSEiEdqCR9EsaQaYOTAM8UV4EIxzOPzoQmFo00VOL4Rngh"

};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

// export const config = process.env.NODE_ENV === "development" ? prod : prod;
