import React, {useEffect, useState} from "react";
import MyTopAppBar from "./header/navbar";
import {Routes} from "../../routes";
import {Footer} from "./my-footer";
import Grid from "@mui/material/Grid";
import {Auth, Logger} from "aws-amplify";
import {AuthService} from "../../screens/auth/service/auth-service";
import {Hub} from "@aws-amplify/core";
import FloatingActionButtonZoom from "../../screens/uploads/upload-floating-button";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {
    BottomNavigation,
    BottomNavigationAction,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Paper
} from "@mui/material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddIcon from '@mui/icons-material/Add';

const logger = new Logger('FullLayout');
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        paddingBottom: 56, // Adjust this value based on the height of your BottomNavigation
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    menu: {
        backgroundColor: "#468eb9",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    drawerPaper: {
        width: 240,
    },
    bottomNav: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: "#ea1f1f",
    },
}));

const UploadDialog = ({open, onClose}) => {
    const actionButtons = [
        {name: 'Upload Receipt', linkTo: '/upload-receipt'},
        {name: 'Upload Findoc', linkTo: '/upload-findoc'}
    ];

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Upload Document</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choose the type of document you want to upload.
                </DialogContentText>
                <List>
                    {actionButtons.map((button) => (
                        <ListItem button component={Link} to={button.linkTo} onClick={onClose} key={button.name}>
                            <ListItemText primary={button.name}/>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export function FullLayout(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [mobileOpen, setMobileOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [value, setValue] = useState('receipts');

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleChange = (event, newValue) => {
        if (newValue === "upload") {
            setUploadDialogOpen(true);
        } else {
            setValue(newValue);
        }
    };

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    };

    useEffect(() => {
        const onHubCapsule = (capsule) => {
            const {channel, payload} = capsule;
            if (channel === AuthService.CHANNEL &&
                payload.event === AuthService.AUTH_EVENTS.LOGIN) {
                if (payload.success) {
                    setLoggedIn(true);
                }
            } else if (channel === AuthService.CHANNEL &&
                payload.event === AuthService.AUTH_EVENTS.SIGN_OUT) {
                if (payload.success) {
                    setLoggedIn(false);
                }
            }
        };

        Hub.listen(AuthService.CHANNEL, onHubCapsule);

        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            if (user) {
                setLoggedIn(true);
                setLoggedInUser(user);
            } else {
                setLoggedIn(false);
                setLoggedInUser({});
            }
        }).catch(err => {
            setLoggedIn(false);
            setLoggedInUser({});
        });
        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };

    }, []);

    return (
        <div className={classes.root}>
            {/* Bottom nav for mobile viewing */}
            {isMobile && (
                <Paper className={classes.bottomNav} elevation={15}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={handleChange}
                        style={{backgroundColor: "#fff"}}
                    >
                        <BottomNavigationAction component={Link} to={"/receipts"} label="Receipts" value={"receipts"}
                                                icon={<ReceiptLongIcon/>}/>
                        <BottomNavigationAction component={Link} to={"/dashboard"} label="Dashboard" value={"dashboard"}
                                                icon={<ReceiptLongIcon/>}/>
                        <BottomNavigationAction component={Link} to={"/findocs"} label="Findocs" value={"findocs"}
                                                icon={<ReceiptLongIcon/>}/>
                        <BottomNavigationAction label="Upload" value={"upload"} icon={<AddIcon/>}/>
                    </BottomNavigation>
                </Paper>
            )}
            <Grid container rowSpacing={1} justifyContent="flex-start" columnSpacing={{xs: 1, sm: 2, md: 3}}
                  style={{width: "100%"}}>
                {/* Top navigation in full view */}
                <Grid item xs={12}>
                    <MyTopAppBar loggedIn={loggedIn} loggedInUser={loggedInUser}/>
                </Grid>
                {/* Main content area */}
                <Grid item xs={12}>
                    <Routes/>
                </Grid>

                {/* Floating action button for desktop view */}
                {!isMobile && (<FloatingActionButtonZoom/>)}

                {/* Footer */}
                <Grid item xs={12}>
                    <Footer/>
                </Grid>

            </Grid>
            <UploadDialog open={uploadDialogOpen} onClose={handleUploadDialogClose}/>
        </div>
    );
}
