import React from 'react';
import {Box, Button, Container, LinearProgress, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(4),
    },
    icon: {
        fontSize: '4rem',
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
        '& button': {
            margin: theme.spacing(1),
        },
    },
    confetti: {
        width: '200px',
        height: '200px',
        background: 'url(/confetti.png) no-repeat center center',
        backgroundSize: 'contain',
        marginBottom: theme.spacing(2),
    }
}));

const PaymentSuccess = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <Box className={classes.confetti}/>
            <CheckCircleIcon className={classes.icon}/>
            <Typography variant="h4" gutterBottom>
                Payment successful
            </Typography>
            <Typography variant="body1" gutterBottom>
                Perfect! Enjoy the experience and start analyzing your data.
            </Typography>
            <Box width="80%" marginTop={2}>
                <LinearProgress variant="determinate" value={67}/>
            </Box>
            <Box display="flex" justifyContent="space-between" width="80%" marginTop={1}>
                <Typography variant="body2">Sites selected</Typography>
                <Typography variant="body2">Payment received</Typography>
                <Typography variant="body2">Processing report</Typography>
            </Box>
            <Box className={classes.buttonContainer}>
                <Button variant="contained" color="primary">
                    New Site
                </Button>
                <Button variant="outlined" color="primary">
                    Back Home
                </Button>
            </Box>
        </Container>
    );
};

export default PaymentSuccess;
