import {Link, Route, Switch} from "react-router-dom";
import {ProtectedRoute} from "./screens/auth/auth-screen";
import React from "react";
import {UploadsScreen} from "./screens/uploads/uploads-screen";
import {ReceiptListScreen} from "./screens/receipts/receipt-list-screen";
import ReceiptsDetailsScreen from "./screens/receipts/receipts-details-screen";
import FaqScreen from "./screens/faq/faqScreen";
import {UploadNewReceipt} from "./screens/receipts/upload-new-receipt";
import PricingPlans from "./screens/payments/payments-pricing-plans";
import {UploadNewFindoc} from "./screens/findoc/upload-new-findoc";
import FindocDetailsScreen from "./screens/findoc/findoc-details-screen";
import {FindocsListScreen} from "./screens/findoc/findocs-list-screen";
import {DashboardScreen} from "./screens/findoc/graphs/dashboard-screen";
import PaymentSuccess from "./screens/payments/payment-success";
import test from "./screens/uploads/test";
import SubscriptionTable from "./screens/findoc/graphs/findoc-subscriptions";

export function Routes() {

    return <Switch>
        {/* Home */}

        <Route
            exact path='/faq'
            component={FaqScreen} B
        />
        <Route
            exact path='/test'
            component={test}
        />
        <Route
            exact path='/payment/success'
            component={PaymentSuccess}
        />
        <Route
            exact path='/payment/canceled'
            component={PaymentSuccess}
        />

        <ProtectedRoute
            exact path='/'
            component={ReceiptListScreen}
        />

        <ProtectedRoute
            exact path='/uploads'
            component={UploadsScreen}
        />

        <ProtectedRoute
            exact path='/upload-receipt'
            component={UploadNewReceipt}
        />

        <ProtectedRoute
            exact path='/upload-findoc'
            component={UploadNewFindoc}
        />
        <ProtectedRoute
            exact path='/receipts'
            component={ReceiptListScreen}
        />
        <ProtectedRoute
            exact path='/receipts/:receiptId'
            component={ReceiptsDetailsScreen}
        />

        <ProtectedRoute
            exact path='/findocs'
            component={FindocsListScreen}
        />

        <ProtectedRoute
            exact path='/findocs/:findocId'
            component={FindocDetailsScreen}
        />

        <ProtectedRoute
            exact path='/dashboard'
            component={DashboardScreen}
        />

        <ProtectedRoute
            exact path='/dashboard/subscriptions'
            component={SubscriptionTable}
        />

        <ProtectedRoute
            exact path='/billing'
            component={PricingPlans}
        />


        <Route path="*">
            <div style={{textAlign: 'center'}}>
                <h3>
                    Couldn't find <code>{window.location.pathname}</code>
                    <br/>
                    Maybe you want to go <Link to="/receipts">home?</Link>
                </h3>
            </div>
        </Route>

    </Switch>
}
