import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import { Hub, Logger } from '@aws-amplify/core';
import { AuthService } from "./service/auth-service";
import { config } from "./service/constants";
import { Alert, Box } from '@mui/material';
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    reasonsContainer: {
        display: 'flex',
    },
    reasons: {
        textAlign: 'left',
        padding: theme.spacing(8, 4),
        backgroundColor: '#f3f3ed', // beige background for the left panel
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    reasonsHeader: {
        marginBottom: theme.spacing(2),
    },
    reasonsList: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: 0,
        listStyle: 'none',
        '& li': {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
        },
        '& li::before': {
            content: '"✔"',
            marginRight: theme.spacing(1),
            color: '#f50057',
        },
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#e6e2e2', // light blue background for the right panel
        padding: theme.spacing(8, 4),
    },
    paper: {
        padding: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#e6e6dd', // light blue background for the right panel
    },
    authbox: {
        padding: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export function RegisterForm() {
    const classes = useStyles();
    const logger = new Logger("RegisterForm");
    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState("");
    const [userNotConfirmed, setUserNotConfirmed] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
    const [password, setPassword] = useState('');

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');

        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };
    }, []);

    const onHubCapsule = async (capsule) => {
        const { channel, payload } = capsule;
        if (channel === AuthService.CHANNEL && payload.event === AuthService.AUTH_EVENTS.REGISTER) {
            if (!payload.success) {
                setErrorMessage(payload.message);
                logger.info("Couldn't register: " + payload.message);
            } else {
                history.push(config.endpointAfterRegistration);
            }
        }
    };

    const onFinish = e => {
        e.preventDefault();
        localStorage.setItem('savedEmail', email);
        AuthService.register(email, password);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid container direction="row" className={classes.reasonsContainer}>
                <Grid item xs={12} sm={6} md={6} className={classes.reasons}>
                    <Paper className={classes.paper} elevation={5}>
                        <Typography component="h1" variant="h5" className={classes.reasonsHeader}>
                            What's included
                        </Typography>
                        <ul className={classes.reasonsList}>
                            <li>Credit Card & Bank Statements Aggregation</li>
                            <li>Transaction Tracking</li>
                            <li>Receipt Tracking</li>
                            <li>Detailed Spending Analysis</li>
                            <li>Search capabilities</li>
                            <li>Subscription Insights</li>
                            <li>Non-intrusive data collection</li>
                        </ul>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.paperContainer}>
                    <Box className={classes.authbox}>
                        <img src={window.location.origin + '/logos/logo-md.png'} style={{ width: "190px" }} alt={""} />
                        <Typography component="h1" variant="h5">
                            Register
                        </Typography>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                        <form className={classes.form} noValidate onSubmit={onFinish}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onInput={e => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Register
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link to="/forgotpassword1" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/login" variant="body2">
                                        Already registered? Login
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}
