import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Box, TextField } from '@mui/material';
import { Logger } from "@aws-amplify/core";
import { FindocsService } from "../../../services/dao/findocs-service";

const MonthlySpend = () => {
    const logger = new Logger("MonthlySpend");
    const findocsService = new FindocsService();

    const [itemsToShow, setItemsToShow] = useState(10);
    const [monthlySpending, setMonthlySpending] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const fetchMonthlySpending = async () => {
            try {
                const filters = {};
                if (startDate) filters.startDate = startDate;
                if (endDate) filters.endDate = endDate;

                let response = await findocsService.getMonthlySpending(filters);
                if (response && !response.error) {
                    setMonthlySpending(response.body);
                }
            } catch (error) {
                logger.error("Failed to fetch monthly spending:", error);
            }
        };

        fetchMonthlySpending();
    }, [startDate, endDate]);

    const transformData = (data) => {
        const series = [];

        Object.keys(data).forEach((group) => {
            const groupData = data[group].map(item => ({
                x: new Date(item.statementDate).getTime(),
                y: item.balance,
            }));

            series.push({
                name: group,
                data: groupData,
            });
        });

        return series;
    };

    const chartData = transformData(monthlySpending);

    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        title: {
            text: 'Balance Over Time',
            align: 'left'
        },
        xaxis: {
            type: 'datetime',
            title: {
                text: 'Date',
            }
        },
        yaxis: {
            title: {
                text: 'Balance',
            }
        },
        legend: {
            position: 'top',
        },
    };

    return (
        <div>
            <ReactApexChart options={options} series={chartData} type="line" height={350} />
        </div>
    );
};

export default MonthlySpend;
