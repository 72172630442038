import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Logger } from "@aws-amplify/core";
import { FindocsService } from "../../../services/dao/findocs-service";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SubscriptionTable = () => {
    const logger = new Logger("SubscriptionTable");
    const findocsService = new FindocsService();

    const [subscriptions, setSubscriptions] = useState({});

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await findocsService.getSubscriptions({});
                if (response && !response.error) {
                    setSubscriptions(response.body);
                }
            } catch (error) {
                logger.error("Failed to fetch subscriptions:", error);
            }
        };

        fetchSubscriptions();
    }, []);

    const renderSubscriptionTable = (subscriptions) => (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Date</strong></TableCell>
                        <TableCell><strong>Description</strong></TableCell>
                        <TableCell><strong>Amount</strong></TableCell>
                        <TableCell><strong>Status</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subscriptions.map((subscription, index) => (
                        <TableRow key={index}>
                            <TableCell>{subscription.transactionDate}</TableCell>
                            <TableCell>{subscription.description}</TableCell>
                            <TableCell>${subscription.amount}</TableCell>
                            <TableCell>{subscription.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Box sx={{ padding: 3 }}>
            {Object.keys(subscriptions).map(group => {
                const sortedSubscriptions = subscriptions[group].slice().sort((a, b) =>
                    new Date(a.transactionDate) - new Date(b.transactionDate)
                );

                return (
                    <Accordion key={group}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${group}-content`}
                            id={`${group}-header`}
                        >
                            <Typography variant="subtitle1">{group}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ marginLeft: 2 }}>

                                {renderSubscriptionTable(sortedSubscriptions)}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

export default SubscriptionTable;
