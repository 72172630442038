import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import {Logger} from "@aws-amplify/core";
import {ReceiptsService} from "../../services/dao/receipts-service";

const logger = new Logger("ReceiptCard");

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};
const imageModalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300, // Ensure it's above most other components
};
const ReceiptDetailsCard = ({receipt}) => {
    const [open, setOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const toggleImageModal = () => setImageOpen(!imageOpen);

    const handleDownloadCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";

        // Adding receipt metadata
        csvContent += "ID,Vendor Name,Date Created,Total,Address,City,State,Zip Code,Item,Product Code,Price\n";
        receipt.lineItems.forEach(item => {
            csvContent += `${receipt._id},${receipt.vendorName},${receipt.invoiceReceiptDate},${receipt.total},${receipt.address},${receipt.city},${receipt.state},${receipt.zipCode}${item.item || ''},${item.productCode},${parseFloat(item.price.replace(/[^\d.-]/g, '')).toFixed(2)}\n`;
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "receipt_details.csv");
        document.body.appendChild(link);

        link.click();
        link.remove();
    };

    const imageUrl = `https://cdn.assetsmaven.com/${encodeURIComponent(receipt.imageLocation)}`;

    const renderTable = () => {
        if (matches) {
            return receipt.lineItems.map((lineItem, index) => (
                <Box key={index} sx={{marginBottom: 2, border: '1px solid #e0e0e0', borderRadius: '8px', padding: 2}}>
                    <Typography variant="body1"><strong>Item:</strong> {lineItem.item || "Unnamed Item"}</Typography>
                    <Typography variant="body1"><strong>Product Code:</strong> {lineItem.productCode}</Typography>
                    <Typography
                        variant="body1"><strong>Price:</strong> {lineItem.price}
                    </Typography>
                </Box>
            ));
        } else {
            return (
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="line items table" sx={{minWidth: 650, width: '100%'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell align="right">Product Code</TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receipt.lineItems.map((lineItem, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {lineItem.item || "Unnamed Item"}
                                    </TableCell>
                                    <TableCell align="right">{lineItem.productCode}</TableCell>
                                    <TableCell align="right">
                                        {lineItem.price}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 2, width: '100%'}}>
            <Card sx={{
                width: '90%',
                margin: 'auto',
                marginTop: 2,
                marginBottom: 2,
                boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
                borderRadius: '8px',
                border: '1px solid #e0e0e0',
                '&:hover': {
                    boxShadow: '1px 2px 10px rgba(0,0,0,0.3)',
                }
            }}>
                {receipt.imageLocation && (
                    <CardMedia
                        component="img"
                        height="194"
                        image={imageUrl}
                        alt="Receipt Image"
                        onClick={toggleImageModal} // Open image modal on click
                        style={{cursor: 'pointer'}} // Optional: change cursor on hover
                    />
                )}
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{fontWeight: 'medium', color: '#1976d2'}}>
                        {receipt.vendorName}
                    </Typography>
                    <Typography sx={{mb: 1.5, color: '#555'}} color="text.secondary">
                        Date: {receipt.dateCreated}
                    </Typography>
                    <Typography variant="body1" sx={{mb: 2, fontWeight: 'bold'}}>
                        Total: {receipt.total}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        Address: {receipt.address}
                    </Typography>
                    <Grid container direction="column" alignItems="stretch"
                          spacing={1}> {/* Adjusted from alignItems="center" to alignItems="stretch" */}
                        <Grid item xs={12}> {/* Ensure it takes full width */}
                            {renderTable()}
                        </Grid>
                        <Grid item xs={12}> {/* Ensure it takes full width */}
                            <Button
                                startIcon={<FileDownloadIcon/>}
                                variant="outlined"
                                color="primary"
                                onClick={handleDownloadCSV}
                                fullWidth
                            >
                                Download CSV
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...modalStyle, overflow: 'hidden', maxHeight: '90vh'}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Line Items ({receipt.lineItems.length})
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                    <Box sx={{overflowY: 'auto', maxHeight: '80vh'}}>
                        {renderTable()}
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={imageOpen}
                onClose={toggleImageModal}
                aria-labelledby="image-modal-title"
                aria-describedby="image-modal-description"
            >
                <Box sx={imageModalStyle}>
                    <IconButton
                        onClick={toggleImageModal}
                        color="inherit"
                        sx={{position: 'fixed', top: 8, right: 8, color: 'white'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <img src={imageUrl} alt="Receipt" style={{maxHeight: '90%', maxWidth: '90%'}}/>
                </Box>
            </Modal>
        </Box>
    );
};

export default ReceiptDetailsCard;
