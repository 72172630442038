import React from "react";
import Grid from "@mui/material/Grid";
import {Logger} from "@aws-amplify/core";
import {UploadsList} from "./uploads-list";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
const l = new Logger("UploadsScreen");

export function UploadsScreen(props) {
    const classes = useStyles();
    const [fileUploaded, setFileUploaded] = React.useState("");
    const fileUploadedCallback = (fileName) => {
        setFileUploaded(fileName);
    }

    return (
        <Container className={classes.root}>
            <Grid container direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}>

                <Grid container item xs={12} justifyContent="center"
                      alignItems="center"
                      style={{margin: 20}}>
                    <UploadsList key={fileUploaded}/>
                </Grid>
            </Grid>

        </Container>
    );
}

