import {Logger} from "@aws-amplify/core";
import {config} from "../constants";
import {AuthService} from "../../screens/auth/service/auth-service";

const l = new Logger("LabelsService");

export class LabelsService {
    constructor() {
        this.endpoint = config.genericService + "/labels"
    }

    /**
     * Each user has only one active api key, hence this request will delete that one active api key
     *
     * @returns {Promise<null|any>}
     */
    deleteLabel = async (key) => {

        let response = null;

        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(this.endpoint + "/keys/" + key, {
                method: 'DELETE',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json'
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    l.debug('parsed json', json) // access json.body here
                    return json;
                }).catch(function (ex) {
                    l.error("Couldn't get files", ex)
                })
        l.info("Final response: " + JSON.stringify(response));
        return response;
    }

    /**
     * Returns the current active API Key for this user...might return no api keys if there are no active ones
     * @returns {Promise<null|any>}
     */
    getMyLabels = async () => {

        l.info("labels URL: " + this.endpoint);
        let response = null;

        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(this.endpoint, {
                method: 'GET',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response => {
                    let json = response.json();

                    l.info("keys: " + json);
                    return json
                }
            )
                .then(json => {
                    l.info("Retrieved key: " + JSON.stringify(json));
                    return json;
                }).catch(function (ex) {
                    l.error("Couldn't get key", ex)
                })
        return response;
    }
}
