import React, { useState } from 'react';
import {
    Stepper, Step, StepLabel, Button, Typography, Box
} from '@mui/material';

const stepsData = {
    0: {
        label: 'Click the \"+\" button',
        description: 'Choose which document to upload. In this case select Receipt',
        image: '/instructions/receipt-step1.webp'  // Replace with your actual image paths
    },
    1: {
        label: 'Upload or take a new picture',
        description: 'Choose a receipt image or take a picture of it',
        image: '/instructions/receipt-step2.webp'  // Replace with your actual image paths
    },
    2: {
        label: 'Upload it to AssetsMaven',
        description: 'Upload the selected or captured receipt image.',
        image: '/instructions/receipt-step3.webp'
    },
    3: {
        label: 'Done',
        description: 'Browse and search your receipts.',
        image: '/instructions/receipt-step4.webp'
    }
};

const ReceiptStepper = () => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {Object.values(stepsData).map((step, index) => (
                    <Step key={index}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === Object.keys(stepsData).length ? (
                    <div>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            {stepsData[activeStep-1].description}
                        </Typography>
                        <Button onClick={handleReset}>Restart</Button>
                    </div>
                ) : (
                    <div>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Step {activeStep + 1}: {stepsData[activeStep].label}
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {stepsData[activeStep].description}
                        </Typography>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + stepsData[activeStep].image}
                                alt={`Step ${activeStep + 1}`}
                                style={{ width: '100%', maxHeight: 400, objectFit: 'contain' }}
                            />
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Button onClick={handleNext}>
                                {activeStep === Object.keys(stepsData).length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </div>
                )}
            </div>
        </Box>
    );
};

export default ReceiptStepper;
