import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    table: {
        minWidth: 650,
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const SearchResult = ({ data, searchQuery }) => {
    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const filteredLineItems = data.lineItems.filter(item =>
        item.description && item.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const lineItemsToShow = showAll ? data.lineItems : filteredLineItems;

    return (
        <Card className={classes.card}>
            <CardHeader title={data.meta.fileName} />
            <CardContent>
                <Typography variant="body1"><strong>Institution:</strong> {data.institutionName}</Typography>
                <Typography variant="body1"><strong>Address:</strong> {data.institutionAddress}</Typography>
                <Typography variant="body1"><strong>Balance:</strong> {data.balance}</Typography>
                <Typography variant="body1"><strong>Statement Date:</strong> {data.statementDate}</Typography>
                {/*<Typography variant="body1">*/}
                {/*    <strong>File Location:</strong> <a href={`https://s3.amazonaws.com/${data.meta.s3Key}`}>Download PDF</a>*/}
                {/*</Typography>*/}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleShowAll}
                    className={classes.button}
                >
                    {showAll ? 'Hide All' : 'Show All'}
                </Button>

                <TableContainer>
                    <Table className={classes.table} aria-label="line items table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lineItemsToShow.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.transactionDate}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell>{item.amount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default SearchResult;

// Example usage
// const data = {...}; // Your provided JSON data
// const searchQuery = "Amazon"; // Your search query
// <SearchResult data={data} searchQuery={searchQuery} />
