import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    table: {
        minWidth: 650,
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const SearchResult = ({ data, searchQuery }) => {
    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const filteredLineItems = data.lineItems.filter(item =>
        item.item && item.item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const lineItemsToShow = showAll ? data.lineItems : filteredLineItems;

    return (
        <Card className={classes.card}>
            <CardHeader title={data.vendorName} />
            <CardContent>
                <Typography variant="body1"><strong>Vendor Name:</strong> {data.vendorName}</Typography>
                <Typography variant="body1"><strong>Address:</strong> {data.address}</Typography>
                <Typography variant="body1"><strong>Total:</strong> {data.total}</Typography>
                <Typography variant="body1"><strong>Date Created:</strong> {data.dateCreated}</Typography>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleShowAll}
                    className={classes.button}
                >
                    {showAll ? 'Hide All' : 'Show All'}
                </Button>

                <TableContainer>
                    <Table className={classes.table} aria-label="line items table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Product Code</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lineItemsToShow.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.item}</TableCell>
                                    <TableCell>{item.productCode}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default SearchResult;
