import {Logger} from "@aws-amplify/core";
import {config} from "../constants";
import {AuthService} from "../../screens/auth/service/auth-service";

const logger = new Logger("SearchService");

export class SearchService {

    searchEverything = async (query, offset, limit) => {

        logger.info("SearchEverything with " + query);
        let endpoint = config.genericService + "/search/all/"
        let queryParams = "";
        let response = null;

        if (query)
            queryParams = "?phrase=" + query;
        if (offset)
            queryParams = queryParams + "&offset=" + offset;
        if (limit)
            queryParams = queryParams + "&limit=" + limit;

        logger.debug("getBehaviorMetadata() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint + queryParams, {
                method: 'GET',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug("Retrieved behavior: " + JSON.stringify(json));
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        return response.body;
    }
    searchReceipts = async (query, offset, limit) => {

        logger.info("SearchEverything with " + query);
        let endpoint = config.genericService + "/search/receipts"
        let queryParams = "";
        let response = null;

        if (query)
            queryParams = "?phrase=" + query;
        if (offset)
            queryParams = queryParams + "&offset=" + offset;
        if (limit)
            queryParams = queryParams + "&limit=" + limit;

        logger.debug("getBehaviorMetadata() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint + queryParams, {
                method: 'GET',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug("Retrieved behavior: " + JSON.stringify(json));
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        return response.body;
    }
    searchFindocs = async (query, offset, limit) => {

        logger.info("SearchEverything with " + query);
        let endpoint = config.genericService + "/search/findocs"
        let queryParams = "";
        let response = null;

        if (query)
            queryParams = "?phrase=" + query;
        if (offset)
            queryParams = queryParams + "&offset=" + offset;
        if (limit)
            queryParams = queryParams + "&limit=" + limit;

        logger.debug("getBehaviorMetadata() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint + queryParams, {
                method: 'GET',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug("Retrieved behavior: " + JSON.stringify(json));
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        return response.body;
    }

}
