import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, TextField, Box, MenuItem, Select, InputLabel, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Logger } from "@aws-amplify/core";
import { FindocsService } from "../../../services/dao/findocs-service";

const TopSpendingCategories = () => {
    const logger = new Logger("MonthlySpend");
    const findocsService = new FindocsService();

    const [monthlySpending, setMonthlySpending] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [accountId, setAccountId] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        // Set default dates to a span of 4 months starting from today
        const today = new Date();
        const end = today.toISOString().split('T')[0];
        const start = new Date(today.setMonth(today.getMonth() - 4)).toISOString().split('T')[0];
        setStartDate(start);
        setEndDate(end);

        // Fetch available accounts from the backend
        const fetchAccounts = async () => {
            try {
                const response = await findocsService.getAccounts();  // Assuming getAccounts() is a method in FindocsService
                if (response && !response.error) {
                    setAccounts(response.body);  // Assuming response.body contains the list of accounts
                    if (response.body.length > 0) {
                        setAccountId(response.body[0].id);  // Set the first account as default
                    }
                }
            } catch (error) {
                logger.error("Failed to fetch accounts:", error);
            }
        };

        fetchAccounts();
    }, []);

    const fetchMonthlySpending = async () => {
        try {
            let response = await findocsService.getCategoriesWithSum(
                null, // Assuming no offset for now
                null, // Assuming no limit for now
                startDate,
                endDate,
                accountId
            );
            if (response && !response.error) {
                setMonthlySpending(response.body);
            }
        } catch (error) {
            logger.error("Failed to fetch monthly spending:", error);
        }
    };

    const fetchLineItems = async (category) => {
        try {
            let response = await findocsService.getLineItemsByCategory(
                null, // Assuming no offset for now
                null, // Assuming no limit for now
                startDate,
                endDate,
                accountId,
                category
            );
            if (response && !response.error) {
                setLineItems(response.body);
                setSelectedCategory(category);
            }
        } catch (error) {
            logger.error("Failed to fetch line items:", error);
        }
    };

    useEffect(() => {
        if (accountId) {
            fetchMonthlySpending();
        }
    }, [accountId, startDate, endDate]);

    const transformData = (data) => {
        // Sort data in descending order based on 'second' (amount)
        const sortedData = data.sort((a, b) => b.second - a.second);

        // Extract categories and amounts
        const categories = sortedData.map(item => item.first);
        const seriesData = sortedData.map(item => item.second);

        return {
            categories,
            seriesData
        };
    };

    const handleBarClick = (event, chartContext, { dataPointIndex }) => {
        const category = chartContext.w.config.xaxis.categories[dataPointIndex];
        fetchLineItems(category);
    };

    const { categories, seriesData } = transformData(monthlySpending);

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false
            },
            events: {
                dataPointSelection: handleBarClick,
            }
        },
        title: {
            text: 'Top Spending Categories',
            align: 'left'
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'Category',
            }
        },
        yaxis: {
            title: {
                text: 'Total Spending ($)',
            }
        },
        legend: {
            position: 'top',
        },
        colors: ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF', '#33FFF1', '#FFBD33', '#FF5733', '#FFC300', '#DAF7A6'],
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return `$${val.toLocaleString()}`;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#000"]
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                dataLabels: {
                    position: 'top', // Place data labels on top of bars
                }
            }
        },
    };

    const series = [{
        name: 'Spending',
        data: seriesData
    }];

    return (
        <div>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl>
                    <InputLabel>Account ID</InputLabel>
                    <Select
                        value={accountId}
                        onChange={(e) => setAccountId(e.target.value)}
                        displayEmpty
                    >
                        {accounts.map((account) => (
                            <MenuItem key={account.id} value={account.id}>
                                {account.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={fetchMonthlySpending}>
                    Fetch Data
                </Button>
            </Box>
            <ReactApexChart options={options} series={series} type="bar" height={350} />

            {selectedCategory && (
                <Box mt={4}>
                    <h3>Line Items for {selectedCategory}</h3>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Amount (USD)</TableCell>
                                    <TableCell>Category</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lineItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.lineItems.transactionDate || "N/A"}</TableCell>
                                        <TableCell>{item.lineItems.description}</TableCell>
                                        <TableCell align="right">${item.cleanAmount.toLocaleString()}</TableCell>
                                        <TableCell>{item.lineItems.category}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </div>
    );
};

export default TopSpendingCategories;
