import React, {useEffect, useState, useRef} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {Alert, CircularProgress, Grid} from "@mui/material";
import {UploadService} from "../../services/dao/uploader-service";
import {Logger} from "@aws-amplify/core";
import {green} from '@mui/material/colors';

const logger = new Logger("UploadNewReceipt");

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),
    },
    accordion: {
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '100%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const uploaderService = new UploadService();

export const UploadNewReceipt = ({fileUploadedCallback}) => {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [fileSize, setFileSize] = useState(null);

    const [fileUploadError, setFileUploadError] = React.useState("");
    const [fileUploadSuccess, setFileUploadSuccess] = React.useState("");
    const [fileUploadAttempted, setFileUploadAttempted] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const fileInputRef = useRef(null);

    const resizeImage = (file, maxSize, callback) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    callback(blob);
                }, file.type, 0.7); // Adjust the quality parameter as needed
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    const onFileSave = async () => {
        setLoading(true);

        setFileUploadError("");
        setFileUploadSuccess("");

        setFileUploadAttempted(true);

        const selectedFile = file.selectedFile;

        if (selectedFile.size > 9 * 1024 * 1024) {
            resizeImage(selectedFile, 2048, async (resizedBlob) => { // Resize to 2048px max dimension
                const resizedFile = new File([resizedBlob], selectedFile.name, {type: selectedFile.type});
                const response = await uploaderService.uploadFile(resizedFile, "receipt");
                handleUploadResponse(response, resizedFile);
            });
        } else {
            const response = await uploaderService.uploadFile(selectedFile, "receipt");
            handleUploadResponse(response, selectedFile);
        }
    };

    const handleUploadResponse = (response, uploadedFile) => {
        setLoading(false);

        if (response && response.error) {
            setFileUploadError(response?.errorMessage);
        }
        if (response && !response.error) {
            setFileUploadSuccess("File was successfully uploaded");
            if (fileUploadedCallback) fileUploadedCallback(uploadedFile.name);
            setFile(null);
            setFileSize(null);
        }
        logger.info("response: " + response);
    };

    const handleReceiptUpload = (event) => {
        const selectedFile = event.target.files[0];
        const fileType = selectedFile.type;

        const validFileTypes = [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'image/tiff'
        ];

        if (!validFileTypes.includes(fileType)) {
            setFileUploadError("Invalid file type. Only JPEG, PNG, PDF, and TIFF are allowed.");
            setFile(null);
            setFileSize(null);
        } else {
            setFile({
                file: window.URL.createObjectURL(selectedFile),
                selectedFile: selectedFile,
                loaded: 0,
            });
            setFileSize(selectedFile.size);
            setFileUploadError("");
        }
    };

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []); // Run only on component mount

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} bytes`;
        if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
        return `${(size / 1048576).toFixed(2)} MB`;
    };

    return (
        <div className={classes.root}>
            <Grid container direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xl={12}
                  sm={12}
                  sx={{p: 1}}
                  className={classes.accordion}
                  style={{width: "100%"}}
            >
                <Grid item xs={12} style={{width: "100%"}}>
                    {fileUploadAttempted &&
                        <div>
                            {fileUploadError &&
                                <Alert severity="error">{fileUploadError}</Alert>}
                            {fileUploadSuccess &&
                                <Alert severity="success">Your file was uploaded and is processing</Alert>}
                        </div>}

                    <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>Select a file
                            <Tooltip title="A document can be in JPEG, PNG, or TIFF format." arrow>
                                <HelpOutlineIcon fontSize="small"/>
                            </Tooltip>
                        </Typography>
                    </div>
                    <Divider/>
                </Grid>

                {!file &&
                    <Grid item xs={12} style={{width: "100%"}}>
                        <input
                            ref={fileInputRef}
                            style={{display: 'none'}}
                            id="raised-button-file"
                            multiple
                            type="file"
                            accept=".jpeg,.jpg,.png,.pdf,.tiff"
                            onChange={handleReceiptUpload}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="outlined" component="span" fullWidth>
                                Choose a receipt
                            </Button>
                        </label>
                    </Grid>}

                {file &&
                    <Grid item xs={12} style={{width: "100%"}} alignItems="center"
                          justifyContent="center" direction={"row"}>
                        <Button style={{marginBottom: "20px"}} variant="outlined" color="error" href="/receipts"
                                fullWidth>
                            Cancel
                        </Button>
                        <Button style={{marginBottom: "10px"}} variant="contained" color="success" onClick={onFileSave}
                                fullWidth>
                            Upload
                        </Button>
                        {loading &&
                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </Grid>}

                {file &&
                    <Grid container
                          alignItems="center"
                          justifyContent="center"
                          direction={"column"}
                          item xs={12}
                          style={{width: "100%"}}>
                        <Typography variant="caption" alignContent={"center"}>
                            {file.selectedFile.name} ({formatFileSize(fileSize)})
                            <br/>
                        </Typography>
                        <img
                            src={URL.createObjectURL(file.selectedFile)}
                            loading="lazy"
                            alt={"receipt"}
                            width="90%"
                            height="100%"
                        />
                    </Grid>}
            </Grid>
        </div>
    );
};
