import {Logger} from "@aws-amplify/core";
import {config} from "../constants";
import {AuthService} from "../../screens/auth/service/auth-service";

const logger = new Logger("ReceiptsService");

export class ReceiptsService {

    getMyUploads = async (offset, limit) => {
        let endpoint = config.genericService + "/receipt/uploads"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        // logger.debug("Final response: " + JSON.stringify(response));
        // return response;
    }
    getMyReceipts = async (offset, limit) => {
        let endpoint = config.genericService + "/receipt"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    getReceiptLineItems = async (receiptId) => {
        let endpoint = config.genericService + "/receipt/" + receiptId + "/items"
        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        // logger.debug("Final response: " + JSON.stringify(response));
        // return response;
    }
    getReceipt = async (receiptId) => {
        let endpoint = config.genericService + "/receipt/" + receiptId
        logger.debug("getReceipt() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        // logger.debug("Final response: " + JSON.stringify(response));
        // return response;
    }

}
