import {Logger} from "@aws-amplify/core";
import {config} from "../constants";
import {AuthService} from "../../screens/auth/service/auth-service";

const logger = new Logger("FindocsService");

export class FindocsService {

    getMyFindocs = async (offset, limit) => {
        let endpoint = config.genericService + "/findoc"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    getAccounts = async (offset, limit) => {
        let endpoint = config.genericService + "/findoc/accounts"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    getFindoc = async (findocId) => {
        let endpoint = config.genericService + "/findoc/" + findocId
        logger.debug("getFindoc() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
        // logger.debug("Final response: " + JSON.stringify(response));
        // return response;
    }
    reanalyze = async (findocId) => {
        let endpoint = config.genericService + "/findoc/" + findocId + "/reanalyze"

        let response = null;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                method: 'PUT',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    deleteFindoc = async (findocId) => {
        let endpoint = config.genericService + "/findoc/" + findocId

        let response = null;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                method: 'DELETE',
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }

    // Graphs
    getMostVisitedStores = async (offset, limit) => {
        let endpoint = config.genericService + "/findoc/mostencountered"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    getMonthlySpending = async (offset, limit) => {
        let endpoint = config.genericService + "/findoc/insights/monthlyspend"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    getSubscriptions = async (offset, limit) => {
        let endpoint = config.genericService + "/findoc/insights/subscriptions"

        let queryParams = "";
        let response = null;

        if (offset)
            queryParams = "?offset=" + offset;
        // if (limit)
        //     queryParams = "limit=" + limit;

        logger.debug("getUploads() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex)
                })
    }
    getCategoriesWithSum = async (offset, limit, startDate, endDate, accountId) => {
        let endpoint = config.genericService + "/findoc/insights/categories/aggregated";

        let queryParams = [];
        let response = null;

        // Add query parameters
        if (offset) queryParams.push(`offset=${offset}`);
        if (limit) queryParams.push(`limit=${limit}`);
        if (startDate) queryParams.push(`startDate=${startDate}`);
        if (endDate) queryParams.push(`endDate=${endDate}`);
        if (accountId) queryParams.push(`accountId=${accountId}`);

        if (queryParams.length > 0) {
            endpoint += '?' + queryParams.join('&');
        }

        logger.debug("getCategoriesWithSum() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken) {
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)); // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex);
                });
        }
    }
    getLineItemsByCategory = async (offset, limit, startDate, endDate, accountId, category) => {
        let endpoint = config.genericService + "/findoc/lineitems/bycategory";

        let queryParams = [];
        let response = null;

        // Add query parameters
        if (offset) queryParams.push(`offset=${offset}`);
        if (limit) queryParams.push(`limit=${limit}`);
        if (startDate) queryParams.push(`startDate=${startDate}`);
        if (endDate) queryParams.push(`endDate=${endDate}`);
        if (accountId) queryParams.push(`accountId=${accountId}`);
        if (category) queryParams.push(`category=${category}`);

        if (queryParams.length > 0) {
            endpoint += '?' + queryParams.join('&');
        }

        logger.debug("getLineItemsByCategory() URL: " + endpoint);
        let idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken) {
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('parsed json', JSON.stringify(json)); // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get files", ex);
                });
        }
    }

}
