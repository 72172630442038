import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Logger} from "@aws-amplify/core";
import {FindocsService} from "../../services/dao/findocs-service";
import {useHistory, useParams} from "react-router-dom";
import {Auth} from "aws-amplify";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';
import FindocDetailsCard from "./components/findoc-details-card";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%"
    },
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));
const logger = new Logger("FindocDetailsScreen");
const findocsService = new FindocsService();

export default function FindocDetailsScreen() {
    const classes = useStyles();
    const [findocDetails, setFindocDetails] = useState(null);
    const history = useHistory();

    let {findocId} = useParams();

    logger.info("FindocID:   " + findocId)
    useEffect(() => {

        const load = async () => {
            let findocInfo
                = await findocsService.getFindoc(findocId)

            let session = await Auth.currentSession();
            logger.debug("sub: " + session["accessToken"]["payload"]["sub"])

            setFindocDetails(findocInfo.body);
        }

        load();

    }, [findocId])

    const handleNavigateBack = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Box sx={{ marginBottom: 2 }}>
                <Button variant="text" startIcon={<ArrowBack />} color="primary" onClick={handleNavigateBack}>
                    Navigate Back
                </Button>
            </Box>
            {findocDetails && <div>
                <Box sx={{flexGrow: 1, overflow: 'hidden'}}>
                    <FindocDetailsCard findoc={findocDetails}/>
                </Box>

            </div>}


        </div>
    );
}
